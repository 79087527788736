import { mapGetters } from 'vuex'

export default {
	name: 'LoginCallBack',
    computed: {
        ...mapGetters({
            $ls_IsAuthenticated: 'auth/userIsAuthenticated',
        }),
    },
    mounted() {
        if (!this.$ls_IsAuthenticated) {
            this.$store.dispatch('auth/signinRedirectCallback');
        }
    },
};